import React, { useState, useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import dynamic from 'next/dynamic';
import { CustomRxssCloseButton, Flex, Paragraph, RxssBackButton, RxssFowardButton } from '@components';
import { useRXSSContext, useToastContext } from '@context';
import { getRxssErrorMessages, RXSS_STEPS, RxMeasurementInput } from '@utils/constants/rxss';
import { RxssPomsPayload, getWarningsFieldsAsString } from '@ts/rxss';
import { analyzeRxData, uploadRxData } from '@services/poms';
import { useIsMobile } from '@utils/hooks';
import { getMissingFieldsRxss } from '@utils/rxss';
import styles from './RxssWizard.module.scss';
import { useRouter } from 'next/router';

const RxssFields = dynamic(() => import('@components').then(mod => mod.RxssFields));
const RxssForm = dynamic(() => import('@components').then(mod => mod.RxssForm));
const RxssReview = dynamic(() => import('@components').then(mod => mod.RxssReview));

const RXSSWizardBody = {
	[RXSS_STEPS.FIELDS]: <RxssFields />,
	[RXSS_STEPS.FORM]: <RxssForm />,
	[RXSS_STEPS.REVIEW]: <RxssReview />,
};

const RxssWizard = ({ onOpenChange, onSubmit }) => {
	const { locale } = useRouter();
	const { showToast } = useToastContext();
	const [missingFields, setMissingFields] = useState<Array<RxMeasurementInput>>([]);
	const methods = useForm();
	const isMobile = useIsMobile();
	const {
		step,
		setStep,
		isNextStepBlocked,
		formData,
		setFormData,
		setIsNextStepBlocked,
		selectedFields,
		hasTwoPds,
		bundleId,
		uploadResult,
		setUploadResult,
		orderNumber,
		productId,
	} = useRXSSContext();
	const RXSS_ERROR_MESSAGES = getRxssErrorMessages(locale);

	useEffect(() => {
		showToast(null);
	}, [step]);

	useEffect(() => {
		const formValues = methods.getValues();
		if (isNextStepBlocked && step === RXSS_STEPS.FORM) {
			const newMissingFields = getMissingFieldsRxss(formValues, selectedFields, hasTwoPds);
			setMissingFields(newMissingFields);
		}
	}, [isNextStepBlocked, step, selectedFields, Object.values(methods.formState.dirtyFields).length, hasTwoPds]);

	let errorMessage = RXSS_ERROR_MESSAGES[step]?.message ?? '';
	if (isNextStepBlocked && step === RXSS_STEPS.FORM) {
		errorMessage = (
			<>
				{RXSS_ERROR_MESSAGES[step]?.message}
				<ul style={{ listStyleType: 'disc', paddingLeft: '2.4rem' }}>
					{missingFields.map(({ id, label }) => (
						<li style={{ listStyleType: 'disc' }} key={id}>
							{label}
						</li>
					))}
				</ul>
			</>
		);
	}

	const handleOnClose = () => {
		onOpenChange(false);
	};
	const handleOnBack = useCallback(() => {
		const index = Object.values(RXSS_STEPS).findIndex(key => RXSS_STEPS[key] === step);
		if (index > 0) {
			setStep(index - 1);
			setIsNextStepBlocked(false);
		}
	}, [setIsNextStepBlocked, setStep, step]);

	const handleOnSubmit = useCallback(async () => {
		onOpenChange(false);
		const response = await uploadRxData({
			orderNumber: orderNumber,
			rx_data: { ...formData, shopify_product_id: productId },
			shopify_bundle_id: bundleId,
			errors: uploadResult.errors ?? [],
		});
		onSubmit(response.success);
	}, [bundleId, formData, onOpenChange, onSubmit, orderNumber, productId, uploadResult?.errors]);

	const getErrorMessage = (textFields: string) => {
		if (!isMobile) {
			return (
				<ul style={{ listStyleType: 'disc', paddingLeft: '2.4rem' }}>
					<li style={{ listStyleType: 'disc' }}>
						{textFields} {RXSS_ERROR_MESSAGES.RX_ERRORS.message.concat(' ')}
					</li>
				</ul>
			);
		}
		return (
			<>
				<Paragraph style={{ marginBottom: '1rem' }}>
					{textFields} {RXSS_ERROR_MESSAGES.RX_ERRORS.message[0]}
				</Paragraph>
				<Paragraph>{RXSS_ERROR_MESSAGES.RX_ERRORS.message[1]}</Paragraph>
			</>
		);
	};

	const handleOnClick = async () => {
		if (isNextStepBlocked) {
			const dataTags = step === RXSS_STEPS.FORM && missingFields.length > 0 ? { 'data-rxss-error': 'missing_values' } : {};
			showToast(
				{
					type: 'error',
					title: RXSS_ERROR_MESSAGES[step]?.title,
					message: errorMessage,
					actionRequired: true,
					ctaColor: 'transparent-light',
				},
				dataTags
			);
			return;
		}

		const index = Object.values(RXSS_STEPS).findIndex(key => RXSS_STEPS[key] === step);

		if (step === RXSS_STEPS.FORM) {
			const values = methods.getValues() as RxssPomsPayload;
			setFormData(values);
			const response = await analyzeRxData({ ...values, shopify_product_id: productId });
			setUploadResult(response);
			if (response.warnings.length > 0) {
				const { fields, textFields } = getWarningsFieldsAsString(response.warnings);
				const errorMessage = getErrorMessage(textFields);
				showToast(
					{
						type: 'error',
						title: RXSS_ERROR_MESSAGES.RX_ERRORS.title,
						message: errorMessage,
						actionRequired: true,
						ctaColor: 'transparent-light',
					},
					{ 'data-rxss-error': JSON.stringify(fields.toSorted()) }
				);
				return;
			}
		}

		setStep(index + 1);
	};

	return (
		<FormProvider {...methods}>
			<CustomRxssCloseButton onOpenChange={onOpenChange} />
			<div id='pprx-container' className={styles['steps-container']}>
				{RXSSWizardBody[step]}
			</div>
			<Flex className={styles['buttons-container']} gap={3}>
				<RxssBackButton step={step} onBack={handleOnBack} onClose={handleOnClose} />
				<RxssFowardButton step={step} onClick={handleOnClick} onSubmit={handleOnSubmit} selectedFields={selectedFields} />
			</Flex>
		</FormProvider>
	);
};

export default RxssWizard;
