import { Button, Close, Flex, Heading, Modal, Paragraph, RxssWizardStyles as styles } from '@components';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		closeUppercase: translator('close-uppercase'),
		areYouWantToExit: translator('are-you-want-to-exit'),
		enterPrescriptionReceiveFrame: translator('enter-prescription-receive-frame'),
		exit: translator('exit'),
		continueEnteringRx: translator('continue-entering-rx'),
	};
};

const CustomRxssCloseButton = ({ onOpenChange }) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	return (
		<div style={{ position: 'absolute', top: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
			<Modal>
				<Modal.Trigger className={styles['modal-close-button']}>
					<Close label={translations.closeUppercase} />
				</Modal.Trigger>
				<Modal.Content includeCloseButton={false} removePadding className={styles['modal-close']}>
					<Modal.Close className={styles['exit-modal-close-button']}>
						<Close label={translations.closeUppercase} />
					</Modal.Close>
					<Flex column>
						<Heading tag='h5'>{translations.areYouWantToExit}</Heading>
						<Paragraph>{translations.enterPrescriptionReceiveFrame}</Paragraph>
						<Flex gap={3} fullWidth style={{ paddingTop: '2.4rem' }}>
							<Modal.Close>
								<Button color='white' onClick={() => onOpenChange(false)}>
									{translations.exit}
								</Button>
							</Modal.Close>
							<Modal.Close style={{ width: '100%' }}>
								<Button data-rxss-continue fullWidth>
									{translations.continueEnteringRx}
								</Button>
							</Modal.Close>
						</Flex>
					</Flex>
				</Modal.Content>
			</Modal>
		</div>
	);
};

export default CustomRxssCloseButton;
