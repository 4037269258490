/* eslint-disable max-lines */
import { PomsRXAttributes, RxForm } from '@ts/rxss';
import { LOCALE_CODES, translate } from '..';

export enum RXSS_STEPS {
	FIELDS,
	FORM,
	REVIEW,
}

export const RXSS_FIELD_INPUT_IMAGE_ID = '6kSUEC805IA3bgfhgXtJyO';

export const getRxssCtaLabels = (locale: string = LOCALE_CODES.US) => {
	const RXSS_CTA_LABELS = {
		[RXSS_STEPS.FIELDS]: {
			label: translate('enter-prescription-details', locale),
			tag: 'details',
		},
		[RXSS_STEPS.FORM]: {
			label: translate('review', locale),
			tag: 'review',
		},
		[RXSS_STEPS.REVIEW]: {
			label: translate('submit-prescription', locale),
			tag: 'submit',
		},
	};
	return RXSS_CTA_LABELS;
};

export const RXSS_ERROR_MESSAGES = {
	[RXSS_STEPS.FIELDS]: {
		title: 'Select Your Measurement First',
		message: "We'll need to know which items appear on your Rx before moving to the next step!",
	},
	[RXSS_STEPS.FORM]: {
		title: 'Please Take Another Peek',
		message: 'These required inputs are missing:',
	},
	RX_ERRORS: {
		title: 'Please Take Another Peek',
		message: [
			'values you have provided are pretty uncommon. Please confirm their accuracy and submit again.',
			"If you have any questions or concerns, don't hesitate to contact our customer service team at hello@paireyewear.com or call us at (646) 389-9692.",
		],
	},
};

export const getRxssErrorMessages = (locale: string = LOCALE_CODES.US) => {
	const RXSS_ERROR_MESSAGES = {
		[RXSS_STEPS.FIELDS]: {
			title: translate('select-measurement-first', locale),
			message: translate('rx-item-first', locale),
		},
		[RXSS_STEPS.FORM]: {
			title: translate('please-take-other-peak', locale),
			message: translate('required-inputs-missing', locale),
		},
		RX_ERRORS: {
			title: translate('please-take-other-peak', locale),
			message: [
				translate('values-provided-uncommon', locale),
				,
				translate('contact-customer-service-suggestion', locale),
				,
			],
		},
	};
	return RXSS_ERROR_MESSAGES;
};

export type RxFieldOption = {
	id: keyof RxForm;
	label: string;
	tooltip?: string | null;
	measurements: RxMeasurement[];
};

export type RxMeasurementInput = {
	id: PomsRXAttributes;
	label: string;
	values: (typeof RXSS_TOOL_VALUE_RANGES)[keyof typeof RXSS_TOOL_VALUE_RANGES];
};

/**
 * A measurement is a single input field or a group of input fields that shows on the 'Form' step of the prescription flow.
 */
export type RxMeasurement = {
	id: string;
	cmsSlug: string;
	title?: string;
	subtitle?: string | null;
	/** When true, dropdown instruction text will render in the middle of the possible values */
	centerInstruction?: boolean;
	inputs: RxMeasurementInput[];
};

type RxFieldOptions = Record<string, RxFieldOption>;

type RxInputValue = {
	copy: string;
	value: string | null;
};

/**
 * Sphere values can NEVER be listed as N/A whereas other values can be listed as N/A
 */
const createSphereValues = () => {
	const sphereValues = [] as RxInputValue[];
	for (let index = -24.5; index <= 24.5; index = index + 0.25) {
		sphereValues.push({
			copy: `${index < 0 ? '' : '+'}${index.toFixed(2)}`,
			value: `${index}`,
		});
	}
	return sphereValues;
};

const createCylinderValues = () => {
	const cylinderValues = [] as RxInputValue[];
	for (let index = -9; index <= 9; index = index + 0.25) {
		if (index === 0) {
			cylinderValues.push({
				copy: 'N/A',
				value: 'N/A',
			});
		} else {
			cylinderValues.push({
				copy: `${index < 0 ? '' : '+'}${index.toFixed(2)}`,
				value: `${index}`,
			});
		}
	}
	return cylinderValues;
};

const createAddValues = () => {
	const addValues = [] as RxInputValue[];
	for (let index = 0.5; index <= 10; index = index + 0.25) {
		addValues.push({
			copy: `+${index.toFixed(2)}`,
			value: `${index}`,
		});
	}
	return addValues;
};

const createAxisValues = () => {
	const axisValues = [
		{
			copy: 'N/A',
			value: 'N/A',
		},
	] as RxInputValue[];
	for (let index = 0; index <= 180; index++) {
		axisValues.push({
			copy: `${index.toString()}`,
			value: `${index}`,
		});
	}
	return axisValues;
};

const createSinglePdValues = () => {
	const pdValues = [] as RxInputValue[];
	for (let index = 17; index <= 40; index += 0.5) {
		pdValues.push({
			copy: `${index}`,
			value: `${index}`,
		});
	}
	return pdValues;
};

const createPdValues = () => {
	const pdValues = [] as RxInputValue[];
	for (let index = 45; index <= 75; index += 0.5) {
		pdValues.push({
			copy: `${index}`,
			value: `${index}`,
		});
	}
	return pdValues;
};

const createPrismStrengths = () => {
	const prismValues = [] as RxInputValue[];
	for (let index = 0; index <= 8; index += 0.25) {
		if (index === 0) {
			prismValues.push({
				copy: 'N/A',
				value: 'N/A',
			});
		} else {
			prismValues.push({
				copy: `${index < 0 ? '' : '+'}${index.toFixed(2)} △`,
				value: `${index}`,
			});
		}
	}
	return prismValues;
};

const createHorizontalPrismValues = () => {
	const horizontalPrismValues = [];
	for (const { copy, value } of createPrismStrengths()) {
		if (value === null) continue;
		if (value === 'N/A') continue;

		horizontalPrismValues.push({
			copy: `${copy} / Base In (BI)`,
			value: `${copy} BI`,
		});
	}

	horizontalPrismValues.push({
		copy: 'N/A',
		value: 'N/A',
	});

	for (const { copy, value } of createPrismStrengths()) {
		if (value === null) continue;
		if (value === 'N/A') continue;

		horizontalPrismValues.push({
			copy: `${copy} / Base Out (BO)`,
			value: `${copy} BO`,
		});
	}
	return horizontalPrismValues;
};

const createVerticalPrismValues = () => {
	const verticalPrismValues = [];
	for (const { copy, value } of createPrismStrengths()) {
		if (value === null) continue;
		if (value === 'N/A') continue;

		verticalPrismValues.push({
			copy: `${copy} / Base Up (BU)`,
			value: `${copy} BU`,
		});
	}

	verticalPrismValues.push({
		copy: 'N/A',
		value: 'N/A',
	});

	for (const { copy, value } of createPrismStrengths()) {
		if (value === null) continue;
		if (value === 'N/A') continue;

		verticalPrismValues.push({
			copy: `${copy} / Base Down (BD)`,
			value: `${copy} BD`,
		});
	}

	return verticalPrismValues;
};

export const RXSS_TOOL_VALUE_RANGES = {
	sphere: createSphereValues(),
	cylinder: createCylinderValues(),
	axis: createAxisValues(),
	horizontal_prism: createHorizontalPrismValues(),
	vertical_prism: createVerticalPrismValues(),
	prism_strength: createPrismStrengths(),
	h_prism_base: [
		{ copy: 'Base In (BI)', value: 'BI' },
		{ copy: 'Base Out (BO)', value: 'BO' },
	],
	v_prism_base: [
		{ copy: 'Base Up (BU)', value: 'BU' },
		{ copy: 'Base Down (BD)', value: 'BD' },
	],
	add: createAddValues(),
	pd: createPdValues(),
	mono_pd: createSinglePdValues(),
};

export const getRxssToolValueRanges = (locale: string = LOCALE_CODES.US) => {
	const RXSS_TOOL_VALUE_RANGES = {
		sphere: createSphereValues(),
		cylinder: createCylinderValues(),
		axis: createAxisValues(),
		horizontal_prism: createHorizontalPrismValues(),
		vertical_prism: createVerticalPrismValues(),
		prism_strength: createPrismStrengths(),
		h_prism_base: [
			{ copy: translate('base-in-bi', locale), value: 'BI' },
			{ copy: translate('base-out-bo', locale), value: 'BO' },
		],
		v_prism_base: [
			{ copy: translate('base-up-bu', locale), value: 'BU' },
			{ copy: translate('base-down-bd', locale), value: 'BD' },
		],
		add: createAddValues(),
		pd: createPdValues(),
		mono_pd: createSinglePdValues(),
	};
	return RXSS_TOOL_VALUE_RANGES;
};

export const RXSS_FIELD_OPTIONS: RxFieldOptions = {
	sphere: {
		id: 'sphere',
		label: 'Sphere (SPH)',
		tooltip: null,
		measurements: [
			{
				id: 'sphere',
				title: 'Sphere',
				subtitle: 'SPH',
				cmsSlug: 'rxss-sphere',
				centerInstruction: true,
				inputs: [
					{ id: 'right_sphere', label: 'Sphere OD', values: RXSS_TOOL_VALUE_RANGES['sphere'] },
					{ id: 'left_sphere', label: 'Sphere OS', values: RXSS_TOOL_VALUE_RANGES['sphere'] },
				],
			},
		],
	},
	cylinder: {
		id: 'cylinder',
		label: 'Cylinder (CYL) & Axis',
		tooltip: null,
		measurements: [
			{
				id: 'cylinder',
				cmsSlug: 'rxss-cylinder',
				title: 'Cylinder',
				subtitle: 'CYL',
				centerInstruction: true,
				inputs: [
					{ id: 'right_cylinder', label: 'Cylinder OD', values: RXSS_TOOL_VALUE_RANGES['cylinder'] },
					{ id: 'left_cylinder', label: 'Cylinder OS', values: RXSS_TOOL_VALUE_RANGES['cylinder'] },
				],
			},
			{
				id: 'axis',
				cmsSlug: 'rxss-axis',
				title: 'Axis',
				subtitle: null,
				centerInstruction: false,
				inputs: [
					{ id: 'right_axis', label: 'Axis OD', values: RXSS_TOOL_VALUE_RANGES['axis'] },
					{ id: 'left_axis', label: 'Axis OS', values: RXSS_TOOL_VALUE_RANGES['axis'] },
				],
			},
		],
	},
	add: {
		id: 'add',
		label: 'ADD',
		tooltip: null,
		measurements: [
			{
				id: 'add',
				cmsSlug: 'rxss-add',
				title: 'ADD',
				centerInstruction: false,
				inputs: [
					{ id: 'right_add', label: 'Add OD', values: RXSS_TOOL_VALUE_RANGES['add'] },
					{ id: 'left_add', label: 'Add OS', values: RXSS_TOOL_VALUE_RANGES['add'] },
				],
			},
		],
	},
	prism: {
		id: 'prism',
		label: 'Prism',
		tooltip: null,
		measurements: [
			{
				id: 'vertical_prism',
				cmsSlug: 'rxss-v-prism',
				title: 'Vertical Prism',
				centerInstruction: true,
				inputs: [
					{ id: 'right_prism_vertical_prism', label: 'V Prism OD', values: RXSS_TOOL_VALUE_RANGES['vertical_prism'] },
					{ id: 'left_prism_vertical_prism', label: 'V Prism OS', values: RXSS_TOOL_VALUE_RANGES['vertical_prism'] },
				],
			},
			{
				id: 'horizontal_prism',
				cmsSlug: 'rxss-h-prism',
				title: 'Horizontal Prism',
				centerInstruction: true,
				inputs: [
					{
						id: 'right_prism_horizontal_prism',
						label: 'H Prism OD',
						values: RXSS_TOOL_VALUE_RANGES['horizontal_prism'],
					},
					{
						id: 'left_prism_horizontal_prism',
						label: 'H Prism OS',
						values: RXSS_TOOL_VALUE_RANGES['horizontal_prism'],
					},
				],
			},
		],
	},
	pd: {
		id: 'pd',
		label: 'Pupillary Distance*',
		tooltip: `No worries if you don't have this. We will guide you through the process of measuring it as a next step.`,
		measurements: [
			{
				id: 'pd',
				cmsSlug: 'rxss-pd',
				title: 'Pupillary Distance',
				centerInstruction: true,
				inputs: [
					{ id: 'right_pd', label: 'PD OD', values: RXSS_TOOL_VALUE_RANGES['mono_pd'] },
					{ id: 'left_pd', label: 'PD OS', values: RXSS_TOOL_VALUE_RANGES['mono_pd'] },
					{ id: 'single_pd', label: 'Pupillary Distance', values: RXSS_TOOL_VALUE_RANGES['pd'] },
				],
			},
		],
	},
};

export const getRsxxFieldOptions = (locale: string = LOCALE_CODES.US) => {
	const RXSS_FIELD_OPTIONS: RxFieldOptions = {
		sphere: {
			id: 'sphere',
			label: translate('rxss-field-option-sphere-label', locale),
			tooltip: null,
			measurements: [
				{
					id: 'sphere',
					title: translate('sphere', locale),
					subtitle: translate('sphere-sph', locale),
					cmsSlug: 'rxss-sphere',
					centerInstruction: true,
					inputs: [
						{ id: 'right_sphere', label: translate('sphere-od', locale), values: RXSS_TOOL_VALUE_RANGES['sphere'] },
						{ id: 'left_sphere', label: translate('sphere-os', locale), values: RXSS_TOOL_VALUE_RANGES['sphere'] },
					],
				},
			],
		},
		cylinder: {
			id: 'cylinder',
			label: translate('rxss-field-option-cylinder-label', locale),
			tooltip: null,
			measurements: [
				{
					id: 'cylinder',
					cmsSlug: 'rxss-cylinder',
					title: translate('cylinder', locale),
					subtitle: translate('cylinder-cyl', locale),
					centerInstruction: true,
					inputs: [
						{
							id: 'right_cylinder',
							label: translate('cylinder-od', locale),
							values: RXSS_TOOL_VALUE_RANGES['cylinder'],
						},
						{
							id: 'left_cylinder',
							label: translate('cylinder-os', locale),
							values: RXSS_TOOL_VALUE_RANGES['cylinder'],
						},
					],
				},
				{
					id: 'axis',
					cmsSlug: 'rxss-axis',
					title: translate('axis', locale),
					subtitle: null,
					centerInstruction: false,
					inputs: [
						{ id: 'right_axis', label: translate('axis-od', locale), values: RXSS_TOOL_VALUE_RANGES['axis'] },
						{ id: 'left_axis', label: translate('axis-os', locale), values: RXSS_TOOL_VALUE_RANGES['axis'] },
					],
				},
			],
		},
		add: {
			id: 'add',
			label: translate('add-uppercase', locale),
			tooltip: null,
			measurements: [
				{
					id: 'add',
					cmsSlug: 'rxss-add',
					title: translate('add-uppercase', locale),
					centerInstruction: false,
					inputs: [
						{ id: 'right_add', label: translate('add-od', locale), values: RXSS_TOOL_VALUE_RANGES['add'] },
						{ id: 'left_add', label: translate('add-os', locale), values: RXSS_TOOL_VALUE_RANGES['add'] },
					],
				},
			],
		},
		prism: {
			id: 'prism',
			label: translate('rxss-field-option-prism-label', locale),
			tooltip: null,
			measurements: [
				{
					id: 'vertical_prism',
					cmsSlug: 'rxss-v-prism',
					title: translate('vertical-prism', locale),
					centerInstruction: true,
					inputs: [
						{
							id: 'right_prism_vertical_prism',
							label: translate('vertical-prism-od', locale),
							values: RXSS_TOOL_VALUE_RANGES['vertical_prism'],
						},
						{
							id: 'left_prism_vertical_prism',
							label: translate('vertical-prism-os', locale),
							values: RXSS_TOOL_VALUE_RANGES['vertical_prism'],
						},
					],
				},
				{
					id: 'horizontal_prism',
					cmsSlug: 'rxss-h-prism',
					title: translate('horizontal-prism', locale),
					centerInstruction: true,
					inputs: [
						{
							id: 'right_prism_horizontal_prism',
							label: translate('horizontal-prism-od', locale),
							values: RXSS_TOOL_VALUE_RANGES['horizontal_prism'],
						},
						{
							id: 'left_prism_horizontal_prism',
							label: translate('horizontal-prism-os', locale),
							values: RXSS_TOOL_VALUE_RANGES['horizontal_prism'],
						},
					],
				},
			],
		},
		pd: {
			id: 'pd',
			label: translate('rxss-field-option-pd', locale),
			tooltip: translate('rxss-field-option-pd-tooltip', locale),
			measurements: [
				{
					id: 'pd',
					cmsSlug: 'rxss-pd',
					title: translate('pupillary-distance', locale),
					centerInstruction: true,
					inputs: [
						{
							id: 'right_pd',
							label: translate('pupillary-distance-od', locale),
							values: RXSS_TOOL_VALUE_RANGES['mono_pd'],
						},
						{
							id: 'left_pd',
							label: translate('pupillary-distance-os', locale),
							values: RXSS_TOOL_VALUE_RANGES['mono_pd'],
						},
						{ id: 'single_pd', label: translate('pupillary-distance', locale), values: RXSS_TOOL_VALUE_RANGES['pd'] },
					],
				},
			],
		},
	};
	return RXSS_FIELD_OPTIONS;
};

export const ERROR_MESSAGES_RXSS_PROGRESSIVE = 'requires progressive';
export const ERROR_MESSAGES_RXSS_ULTRA_THIN = 'ultra_thin';
export const ERROR_MESSAGE_NEW_BASE_FRAME =
	'current frame not viable - add ultra thin and suggest one of the following alternative frames';
