import { Button } from '@components';
import { useTranslation } from '@utils/index';

type BackButtonProps = {
	step: number;
	onClose: () => void;
	onBack: () => void;
};

const getTranslatedTexts = translator => {
	return {
		closeUppercase: translator('close-uppercase'),
		back: translator('back'),
	};
};

const BackButton = ({ step, onClose, onBack }: BackButtonProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return step === 0 ? (
		<Button color='white' onClick={onClose}>
			{translations.closeUppercase}
		</Button>
	) : (
		<Button color='white' withChevron={true} chevronDirection='left' chevronPosition='front' onClick={onBack}>
			{translations.back}
		</Button>
	);
};

export default BackButton;
