import { Button } from '@components';
import { RxForm } from '@ts/rxss';
import { RXSS_STEPS, getRxssCtaLabels } from '@utils/constants/rxss';
import { useRouter } from 'next/router';

type FowardButtonProps = {
	step: number;
	onClick: () => Promise<void>;
	onSubmit: () => Promise<void>;
	selectedFields: (keyof RxForm)[];
};

const FowardButton = ({ step, onSubmit, selectedFields, onClick }: FowardButtonProps) => {
	const { locale } = useRouter();
	const RXSS_CTA_LABELS = getRxssCtaLabels(locale);
	const dataTags = step === RXSS_STEPS.FIELDS ? { 'data-rxss-selected-fields': JSON.stringify(selectedFields.toSorted()) } : {};

	return step === 2 ? (
		<Button
			fullWidth
			withChevron={true}
			chevronDirection='right'
			data-rxss-next-step={RXSS_CTA_LABELS[step].tag}
			onClick={onSubmit}
		>
			{RXSS_CTA_LABELS[step].label}
		</Button>
	) : (
		<Button
			fullWidth
			onClick={onClick}
			withChevron={true}
			chevronDirection='right'
			data-rxss-next-step={RXSS_CTA_LABELS[step].tag}
			{...dataTags}
		>
			{RXSS_CTA_LABELS[step].label}
		</Button>
	);
};

export default FowardButton;
